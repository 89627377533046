<div class="auth-body-bg">
	<div class="home-btn d-none d-sm-block"> <a><i class="mdi mdi-home-variant h2 text-white"></i></a>
	</div>
	<div>
		<div class="container-fluid p-0">
			<div class="row no-gutters">
				<div class="col-lg-4">
					<div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
						<div class="w-100">
							<div class="row justify-content-center">
								<div class="col-lg-9">
									<div>
										<div class="text-center">
											<div>
												<a class="logo">
													<img src="assets/images/logo-final.png" height="50" alt="logo">
												</a>
											</div>
											<h4 class="font-size-18 mt-3">Welcome to Login</h4>
										</div>
										<div class="p-2 mt-3">
											<form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="submit()">
												<div class=" mb-4">
													<div class="form-group auth-form-group-custom mb-0"> <i
															class="ri-user-2-line auti-custom-input-icon"></i>
														<label for="username">Email</label>
														<input type="text" class="form-control" id="username" (keydown.space)="$event.preventDefault()"
															placeholder="Enter email id" formControlName="email">
													</div>

													<div class="error-msg" *ngIf="loginFormSubmitted && a.email.errors">
														<span style="color:red"
															*ngIf="a.email.errors.required">Email id
															is required.</span>
													</div>
												
												</div>
												<div class=" mb-4">
													<div class="form-group auth-form-group-custom mb-0"> <i
															class="ri-lock-2-line auti-custom-input-icon"></i>
														<label for="userpassword">Password</label>
														<input [type]="password ? 'text' : 'password' " (keydown.space)="$event.preventDefault()"
															class="form-control" placeholder="Enter password"
															formControlName="password"> <i (click)="showPassword()"
															class=" auti-custom-input-icon auti-custom-eye"
															[ngClass]="password ? 'ri-eye-fill' : 'ri-eye-line'"></i>
													</div>
													<div class="error-msg" *ngIf="loginFormSubmitted && a.password.errors">
														<span style="color:red"
															*ngIf="a.password.errors.required">Password
															is required.</span>
													</div>
												</div>
												<div class="custom-control custom-checkbox" style="display: none;">
													<input type="checkbox" class="custom-control-input"
														id="customControlInline">
													<label class="custom-control-label"
														for="customControlInline">Remember me</label>
												</div>
												<div class="mt-4 text-center">
													<button class="btn btn-primary w-md waves-effect waves-light"
														type="submit">Log In</button>
												</div>
												<!-- [routerLink]="['/send-otp']" -->
												<div class="mt-4 text-center" [routerLink]="['/send-otp']"> <a class="text-muted"><i
															class="mdi mdi-lock mr-1"></i>
														Forgot
														your password?</a>
													<!-- <div class="alert alert-success p-2 mt-2 mb-0 font-size-12"
														role="alert">An email has been sent to the registered email id
														for password reset.</div> -->
												</div>
											</form>
										</div>
										<div class="mt-3 text-center  copyright">
											<p>© 2023 e-SAMRIDH Crafted with <i class="mdi mdi-heart text-danger"></i> by
												<a href="https://greychaindesign.com/">Grey Chain</a></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-8">
					<div class="authentication-bg">
						<div class="bg-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>