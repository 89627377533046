import { Component } from '@angular/core';
import { CommonService } from './services/common/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'e-SAMRIDH-admin';
  public static currencySign: string = "₹";
  constructor(){
  }
}
