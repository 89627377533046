import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { regex } from 'src/app/services/http/http-wrapper/regex';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent {
  OtpForm:FormGroup;
  submitted=false;

  constructor(
    private authService:AuthService,
    private commonService:CommonService,
    private router:Router,
    private storage:StorageService,


  ) { }

  ngOnInit(): void {
    this.OtpForm=new  FormGroup({
      otp : new FormControl('',Validators.compose([Validators.required])),
    });
  }

  get a(){
    return this.OtpForm.controls;
  }


  veriFyOtp(){
    this.submitted=true;
    if(this.OtpForm.invalid){
      return;
    }

    let savedData=this.storage.getSavedData();
    if(savedData && savedData.authData){
      this.OtpForm.value.adminId=savedData.authData._id
    }
    this.commonService.presentSpinner();
    this.authService.verifyOtp(this.OtpForm.value).then((res:any)=>{
      if(res.code ==200){
        this.commonService.dismissSpinner();
        this.commonService.showSuccessToastMsg(res.message);
        this.router.navigate(['/reset-password']);

      }
    }).catch((error)=>{
      this.commonService.dismissSpinner();
    }).finally(()=>{
      this.commonService.dismissSpinner();

    })
  }
  onlyNumberKey(event: any) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
 
}
