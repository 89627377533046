<div class="auth-body-bg">
    <div class="home-btn d-none d-sm-block"> <a><i class="mdi mdi-home-variant h2 text-white"></i></a>
    </div>
    <div>
        <div class="container-fluid p-0">
            <div class="row no-gutters">
                <div class="col-lg-4">
                    <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                        <div class="w-100">
                            <div class="row justify-content-center">
                                <div class="col-lg-9">
                                    <div>
                                        <div class="text-center">
                                            <div>
                                                <a class="logo">
                                                    <img src="assets/images/logo-final.png" height="50" alt="logo">
                                                </a>
                                            </div>
                                            <h4 class="font-size-18 mt-3">One-Time Passcode Verification</h4>
                                        </div>
                                        <div class="p-2 mt-3">
                                            <form class="form-horizontal" [formGroup]="OtpForm"
                                                (ngSubmit)="veriFyOtp()">
                                                <div class="mb-4">
                                                    <div class="form-group auth-form-group-custom mb-0"> <i
                                                        class="fa fa-key auti-custom-input-icon"></i>
                                                        <label for="username">OTP</label>
                                                        <input type="text" class="form-control" id="username"
                                                            (keydown.space)="$event.preventDefault()"  maxlength="6"
                                                            placeholder="Enter OTP"
                                                            formControlName="otp" (keypress)="onlyNumberKey($event)">
                                                    </div>
                                                    <div class="invalid-feedback" *ngIf="submitted && a.otp.errors">
                                                        <div *ngIf="a.otp.errors.required"> Otp is required.</div>
                                                    </div>
                                                </div>

                                                <div class="mt-4 text-center">
                                                    <button class="btn btn-primary w-md waves-effect waves-light"
                                                        type="submit">Verify OTP</button>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="mt-3 text-center">
                                            <p>Don't have an account ? <a [routerLink]="['/login']"
                                                    class="font-weight-medium text-primary">
                                                    Log in </a>
                                            </p>

                                            <p class="copyright">© 2023 e-SAMRIDH Crafted with <i
                                                    class="mdi mdi-heart text-danger"></i> by <a
                                                    href="https://greychaindesign.com/">Grey Chain</a></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="authentication-bg">
                        <div class="bg-overlay"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>