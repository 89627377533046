

const admin = "/admin/v1/";
const supplier = "/supplier/v1/"

export const EndpointTwo = {
  "auth": {
    "login": `${admin}login`,
    "dashboard": `${admin}/dashboard`,
    "logout": `${admin}/logout`,
    "changePassword": `${admin}/change-password`,
    "otp": `${admin}forgot-password`,
    "otpVerify": `${admin}otp-verify`,
    "resetPassword": `${admin}reset-password`,

  },
  // 
  //
  "db": {
    "dashboard": (startDate: string, endDate: string, period: number) => { return `${admin}dashboard?period=${period}` + (startDate ? `&startDate=${startDate}` : '') + (endDate ? `&endDate=${endDate}` : '') }
  },

  "customer": {
    "list": `${admin}customers`,
    "status": `${admin}customer`,
    "delete": `${admin}customer`,
    "customerDeatls": (customer: string) => { return `${admin}customer/${customer}` },
    "order": (customer: string) => { return `${admin}customer-order/${customer}` },
  },
  "supplier": {
    "list": `${admin}suppliers`,
    "status": (supplierId: string) => { return `${admin}suppliers/${supplierId}` },
    "delete": (supplierId: string) => { return `${admin}suppliers/${supplierId}` },
    'imageUpload': {
      upload: `${supplier}upload-supplier`
    }
  },

  "product": {
    "list": `${admin}products`,
    "status": (productId: string) => { return `${admin}product/${productId}` },
    "delete": `${admin}product`,
    "addClassification": `${admin}classification`,
    "details": (productId: string) => { return `${admin}product/${productId}` },
    // "uploadProductimage": `${admin}upload-product`,
    "uploadProductimage": `${supplier}upload-supplier`,
    "classification": (page: number, limit: number, search: string) => { return `${admin}product-classifications?page=${page}&limit=${limit}` + (search ? `&search=${search}` : '') },
    "classiFicationStatus": (classificationId: string) => { return `${admin}classification/${classificationId}` },
    "contractUpload": (productId: string) => { return `${admin}product/${productId}` },
    "exportPdf": (page: number, limit: number) => { return `${admin}products?page=${page}&limit=${limit}` },
    "imageDelete": (productId: string) => { return `${admin}delete-product-image/${productId}` },
    "getSupplier": `${admin}getSupplier`,
    "getCustomers": "/admin/v1/customer-list",
    "update": (productId: string) => { return `/supplier/v1/product/${productId}` },
  },

  "category": {
    "list": `${admin}category`,
    "delete": `${admin}category`,
    "add": `${admin}category`,
    "updateCategory": (category_id: string) => { return `${admin}category/${category_id}` },
    "categoryStatus": (category_id: string) => { return `${admin}category-status/${category_id}` },
    "categoryReorder": `${admin}category-reorder`,
    "categoryList": "/supplier/v1/product-categories",
    "subCategoryList": "/supplier/v1/product-sub-categories",
  },

  "subCategory": {
    "list": (category_id: string, page: number, limit: number, search: string, status: string) => { return `${admin}sub-category/${category_id}?page=${page}&limit=${limit}` + (search ? `&search=${search}` : '') + (status ? `&status=${status}` : '') },
    // "list":(category_id: string,search:string,status:string) => { return `${admin}sub-category/${category_id}` + (search ? `&search=${search}` : '') + (status ? `&status=${status}` : '')} ,
    "status": (category_id: string) => { return `${admin}sub-category/${category_id}` },
    "delete": `${admin}subCategory`,
    "add": `${admin}sub-category`,
    "update": (category_id: string) => { return `${admin}sub-category/${category_id}` },
    "reorder": `${admin}sub-category-reorder`
  },

  "service": {
    "list": `${admin}services`,
    "status": (serviceId: string) => { return `${admin}services/${serviceId}` },
    "imageDelete": (productId: string) => { return `${admin}delete-service-image/${productId}` },
    "serviceDetail": (serviceId: string) => { return `/supplier/v1/service/${serviceId}` },
    "update": (serviceId: string) => { return `/supplier/v1/service/${serviceId}` },
  },
  "regSupplier": {
    "register": (supplierId: string) => { return `${supplier}register/${supplierId}` },
    "preview": (supplierId: string) => { return `${admin}preview-edit-request/${supplierId}` },
    "permission": (supplierId: string) => { return `${admin}accept-edit-request/${supplierId}` },
    "reject": (supplierId: string) => { return `${admin}reject-edit-request/${supplierId}` },
  },
  "system": {
    "banners": `${admin}banners`,
    "addBanners": `${admin}banner`,
    "editBanners": (bannerId: string) => { return `${admin}banner/${bannerId}` },
    "deleteBanners": (bannerId: string) => { return `${admin}banner/${bannerId}` },
    "updateContent": (contentId: string) => { return `${admin}content?contentId=${contentId}` },
    "contentlist": `${admin}content`,
    "termsConditions": `${admin}terms-conditions`,
    "privacyPolicy": `${admin}privacy-policy`,
    "aboutUs": `${admin}about-us`,
    "faq": `${admin}faqs`,
    "faqReorder": `${admin}faqs-reorder`,
    "addUpdate": `${admin}faqs`,
    "updateFaqQues": `${admin}update-faq-question`,
    "deleteFaq": (faqid: string) => { return `${admin}/faqs/${faqid}` },
    "deleteFaqQues": `${admin}/delete-faq-question`,
    "Achievements": {
      'List': `${admin}achievements`,
      'add': `${admin}achievements`,
      'edit': (AchievementId: string) => { return `${admin}/achievements/${AchievementId}` },
      'delete': (AchievementId: string) => { return `${admin}/achievements/${AchievementId}` },
    },
    "video": {
      'add': `${admin}video`,
      'deleteAndUpdate': (videoId: string) => { return `${admin}/video/${videoId}` },
      'list': (page: number, limit: number) => { return `${admin}video?page=${page}&limit=${limit}` },
    }
  },
  "order": {
    'list': `${admin}order`,
    'details': (orderId: string) => { return `${admin}/orderDetails?orderId=${orderId}` },
    'cancelOrder': (orderId: string) => { return `${admin}/cancelOrder/${orderId}` },
    'download': `${admin}OrderExcelsheet`,
    "orderCancel": (orderId: string) => { return `${admin}cancel-order/${orderId}` },
    "orderStatus": (orderId: string) => { return `${admin}change-status/${orderId}` },
    "acceptInstallationRequest": "/admin/v1/accept-installation",
    "acceptInstallationCompleted": "/admin/v1/complete-installation ",
    "updateAmountForModerateOrder": (orderId: string) => {return `/admin/v1/order-amount/${orderId}`}
  },
  "query": {
    'list': `${admin}query-list`,
    'listForSeller': `/admin/v1/initiate-chat`,
    'send': `/common/v1/query-request`,
    'sendQueryToSeller': `/common/v1/query-request-seller`,
    "buyerQueryStatusChange": (queryId: string) => { return `/common/v1/buyer-status-change/${queryId}` },
    "sellerQueryStatusChange": (queryId: string) => { return `/common/v1/seller-status-change/${queryId}` },
    "fileUploadForInitiateChat": "/customer/v1/upload-customer",
    "initiateChatWithSeller": "/admin/v1/initiate-chat"
  },
  "support": {
    'list': `${admin}support-list`,
    "changeStatus": (id: string) => { return `${admin}change-support-status/${id}` }
  },
  "exportFile": {
    'productFile': (startDate: string, endDate: string) => { return `${admin}downloadCustomerExcelsheet?` + (startDate ? `startDate=${startDate}` : '') },
  },
  "review": {
    'list': `${admin}reviews`,
    'delete': (id: string) => { return `${admin}review/${id}` },
  },
  read: {
    "read-seller": (id) => { return `${admin}read-seller/${id}` },// seller
    "read-service": (id) => { return `${admin}read-service/${id}` },//service
    "read-order": (id) => { return `${admin}read-order/${id}` }, //order
    "read-buyer-query": (id) => { return `${admin}read-buyer-query/${id}` },// buyer query
    "read-seller-query": (id) => { return `${admin}read-seller-query/${id}` },// seller query
    "read-support": (id) => { return `${admin}read-support/${id}` },// support
    "read-review": (id) => { return `${admin}read-review/${id}` },// review
    "readCount": `${admin}count-unread-list`,// for management count
  },

  "user-management":{
    "admin":`${admin}admin`,
    "addUpdate":`${admin}admin`,
    "adminDelete": (id) => { return `${admin}admin-status/${id}` }
  }

}