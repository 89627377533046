import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { OtpRequestComponent } from './components/otp-request/otp-request.component';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';
import { UserRole } from './constants/user.roles';
import { AuthGuard } from './Guard/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: '/e-SAMRIDH-admin/buyer-listing', pathMatch: 'full' },
  { path: 'e-SAMRIDH-admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule),data: { type: 'postLogin', roles: [UserRole.superadmin, UserRole.operations,UserRole.programManagement] },
  canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ForgotPasswordComponent },
  { path: 'send-otp', component:OtpRequestComponent },
  { path: 'send-verification', component:OtpVerificationComponent },
  { path: '**', redirectTo:'' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
