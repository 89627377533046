import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginPayload } from 'src/app/services/auth/auth.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Storage_Keys } from 'src/app/services/storage/storage.keys';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  password: boolean;
  loginForm:UntypedFormGroup;
  loginFormSubmitted=false;
  loginApiErrorResponse: HttpErrorResponse;

  constructor(
    private commonService:CommonService,
    private authService:AuthService,
    private storage:StorageService,
    private router:Router
  ) {
    this.loginForm=new UntypedFormGroup({
      "email":new UntypedFormControl('',Validators.compose([Validators.required])),
      "password":new UntypedFormControl('',Validators.compose([Validators.required])),

    })
  }

  ngOnInit(): void {}
  showPassword() {
    this.password = !this.password;
  }

  get a(){
    return this.loginForm.controls;
  }

  submit(){    
    if(this.loginForm.invalid){
    this.loginFormSubmitted=true;

      return;
    }

    let payload=new LoginPayload(this.loginForm.value);
    payload = this.commonService.convertEmailToLowerCase(payload, "login");
    this.commonService.presentSpinner();
    this.authService.login(payload).then((res:any)=>{
      if(res && res.admindata){                
        this.storage.set(Storage_Keys.admin_data, res.admindata);
        this.storage.set(Storage_Keys.token, res.admindata.JWT_Token[0]);
        this.router.navigate(['/e-SAMRIDH-admin/buyer-listing']);
        this.commonService.showSuccessToastMsg(res.message);
      }
    }).catch((error)=>{
      this.loginApiErrorResponse = error;
      // this.commonService.showErrorToastMsg(error.error.message);

    }).finally(()=>{
      this.commonService.dismissSpinner();

    })


  }

}
