import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpWrapperService } from '../http/http-wrapper/http-wrapper.service';
import { EndpointTwo } from '../http/http-wrapper/endpoint';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  data = new Subject();
  download = new Subject();
  private shareData = new BehaviorSubject<any>(null);
  pageRefresh: Subject<boolean> = new Subject<boolean>();
  refreshCount: Subject<boolean> = new Subject<boolean>();
  menusList = [
    { route: '/e-SAMRIDH-admin/buyer-listing', "countKey": "buyerUnreadCount", name: 'Buyer', icon: 'mdi mdi-account-group-outline', innerRouter: [] },
    { route: '/e-SAMRIDH-admin/seller-listing', "countKey": "sellerUnreadCount", name: 'Seller', icon: 'mdi mdi-account-group-outline', innerRouter: [] },
    { route: '/e-SAMRIDH-admin/product-list', "countKey": "productUnreadCount", name: 'Product', icon: 'mdi mdi-dropbox', innerRouter: [] },
    { route: '/e-SAMRIDH-admin/service-list', "countKey": "serviceUnreadCount", name: 'Service', icon: 'fa fa-cog', innerRouter: [] },
    { route: '/e-SAMRIDH-admin/Order-list', "countKey": "orderUnreadCount", name: 'Order', icon: 'ri-shopping-cart-line', innerRouter: [] },
    { route: '/e-SAMRIDH-admin/user-management', "countKey": "", name: 'user-management', icon: 'mdi mdi-account-group-outline', innerRouter: [] },
    {
      route: '/e-SAMRIDH-admin/Data_Management', "countKey": "managementTotalCount", name: 'Management', icon: 'mdi mdi-account-group-outline', innerRouter: [
        { link: '/e-SAMRIDH-admin/Data_Management', "countKey": "", name: 'Data Management', icon: 'fa fa-database' },
        { link: '/e-SAMRIDH-admin/Content_Management', "countKey": "", name: 'Content Management', icon: 'ri-settings-4-line' },
        { link: '/e-SAMRIDH-admin/Query', "countKey": "queryUnreadTotal", name: 'Queries', icon: 'fa fa-question-circle' },
        { link: '/e-SAMRIDH-admin/Support', "countKey": "supportUnreadCount", name: 'Support', icon: 'fa fa-headset' },
        { link: '/e-SAMRIDH-admin/Report', "countKey": "", name: 'Report', icon: 'mdi mdi-file-document' },
        { link: '/e-SAMRIDH-admin/review', "countKey": "reviewUnreadCount", name: 'Review', icon: 'mdi mdi-message-draw' },
      ]
    },
  ]
  constructor(
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private router: Router,
    private http: HttpWrapperService,
    private storageService:StorageService
    
  ) {

  }

  presentSpinner() {
    this.spinner.show();
  }

  dismissSpinner() {
    this.spinner.hide();
  }

  dismisDownloadSpinner(name?: string) {
    this.spinner.hide(name);
  }

  pressentDownloadSpinner(name?: string) {
    this.spinner.show(name);
  }

  showSuccessToastMsg(title?: string, message?: string) {
    this.toast.success(message, title, {
      timeOut: 2000
    });
  }

  showErrorToastMsg(title?: string, message?: string) {
    this.toast.error(message, title, {
      timeOut: 3000
    });
  }

  matchConfirmPassword(formGroup: UntypedFormGroup) {
    const password = formGroup.get('newPassword').value;
    const confirmPassword = formGroup.get('confirmPassword').value;

    let returnAble = password === confirmPassword ? null : { notSame: true };

    return returnAble
  }

  setNewInfo(user: any) {
    this.shareData.next(user);
  }
  getNewInfo() {
    return this.shareData.asObservable();
  }

  refresh() {
    const prev = this.router.routeReuseStrategy.shouldReuseRoute;
    const prevOSN = this.router.onSameUrlNavigation;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    location.reload();

    this.router.navigateByUrl(this.router.url);
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = prev;
      this.router.onSameUrlNavigation = prevOSN;
    }, 0);
  }

  getSectionUnReadCount(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(EndpointTwo.read.readCount).subscribe(({ countData }) => {
        resolve(countData);
      }, (error) => {
        resolve(null);
      });
    });
  }

  readSeller(sellerId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(EndpointTwo.read['read-seller'](sellerId)).subscribe((res) => {
        if (res && res.code == 200) {
          this.refreshCount.next(true);
          resolve(res);
        } else {
          resolve(null);
        }
      }, (error) => {
        resolve(null);
      });
    });
  }

  readService(serviceId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(EndpointTwo.read['read-service'](serviceId)).subscribe((res) => {
        if (res && res.code == 200) {
          this.refreshCount.next(true);
          resolve(res);
        } else {
          resolve(null);
        }
      }, (error) => {
        resolve(null);
      });
    });
  }

  readOrder(orderId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(EndpointTwo.read['read-order'](orderId)).subscribe((res) => {
        if (res && res.code == 200) {
          this.refreshCount.next(true);
          resolve(res);
        } else {
          resolve(null);
        }
      }, (error) => {
        resolve(null);
      });
    });
  }

  readBuyerQuery(queryId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(EndpointTwo.read['read-buyer-query'](queryId)).subscribe((res) => {
        if (res && res.code == 200) {
          this.refreshCount.next(true);
          resolve(res);
        } else {
          resolve(null);
        }
      }, (error) => {
        resolve(null);
      });
    });
  }

  readSellerQuery(queryId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(EndpointTwo.read['read-seller-query'](queryId)).subscribe((res) => {
        if (res && res.code == 200) {
          this.refreshCount.next(true);
          resolve(res);
        } else {
          resolve(null);
        }
      }, (error) => {
        resolve(null);
      });
    });
  }

  readSupport(supportId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(EndpointTwo.read['read-support'](supportId)).subscribe((res) => {
        if (res && res.code == 200) {
          this.refreshCount.next(true);
          resolve(res);
        } else {
          resolve(null);
        }
      }, (error) => {
        resolve(null);
      });
    });
  }

  readReview(reviewId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(EndpointTwo.read['read-review'](reviewId)).subscribe((res) => {
        if (res && res.code == 200) {
          this.refreshCount.next(true);
          resolve(res);
        } else {
          resolve(null);
        }
      }, (error) => {
        resolve(null);
      });
    });
  }

  permission() {
    const saveData = this.storageService.getSavedData();

    if (saveData && saveData.admin_data) {
        let userRole = 'operations';

        if (userRole === 'superadmin') {
            return this.menusList;
        } else if (userRole === 'operations') {
            const filteredMenus = [];
            for (const data of this.menusList) {
                if (data.innerRouter.length > 0) {
                    const filteredInnerRouter = data.innerRouter.filter(list => list.name !== 'Review' && list.name !== 'Report');
                    filteredMenus.push(...filteredInnerRouter);
                }
            }
            return this.menusList;
        }
    }
    // Handle other cases, like userRole not being 'superadmin' or 'operations'
    // Return null or handle appropriately based on your application's logic
    return null;
}


convertEmailToLowerCase(payload: any, page: "admin-add-edit" | "login" | "forgot-password") {
  switch (page) {
    case "admin-add-edit":
      payload.email = payload.email.toLowerCase();
      return payload;
      break;
  
    case "login":
      payload.email = payload.email.toLowerCase();
      return payload;
      break;
      
    case "forgot-password":
      payload.email = payload.email.toLowerCase();
      return payload;
      break;
  
    default:
      break;
  }
}

}

export class Entries {
  from: number = 0;
  to: number = 0;
  total: number = 0;

  constructor(list?: Array<any>, current_page?: number, limit?: number, totalResult?: number) {
    list = list && list.length ? list : [];
    if (!totalResult) {
      totalResult = list.length;
    }
    if (!limit) {
      limit = list.length;
    }
    if (list.length && list.length === totalResult) {
      let pages = {
        1: []
      };
      let arr = [];
      let page = 1;
      for (let i = 0; i < list.length; i++) {
        arr.push(list[i]);
        if (arr.length == limit) {
          pages[page] = arr;
          page += 1;
          arr = [];
        } else {
          if (i == list.length - 1) {
            pages[page] = arr;
            page += 1;
            arr = [];
          }
        }
      }
      list = pages[current_page];
    }
    this.from = limit * (current_page - 1) + 0 + 1;
    this.to = limit * (current_page - 1) + (list.length - 1) + 1;
    this.total = totalResult;
  }

  // downloadPdf(url: string): Observable<Blob> {
  //   return this.HttpClient.get(url, { responseType: 'blob' });
  // }
}

import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { StorageService } from '../storage/storage.service';
import { UserRole } from 'src/app/constants/user.roles';

export const isValidDate: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  // Regular expression to validate YYYY-MM-DD format
  const DATE_REGEXP = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;

  // Check if the value matches the format and is not a future date
  return DATE_REGEXP.test(control.value) && new Date(control.value) <= new Date() || control.value === '' ? null : {
    validateDate: {
      valid: false
    }
  };
};




