

<router-outlet></router-outlet>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" name="downloading" size="medium" color="#ff0000" type="ball-clip-rotate"
  [fullScreen]="true">
  <p style="color: #fff;">Downloading ({{downloadPercent || 0}}%)...</p>

</ngx-spinner> -->

<!-- // //-->