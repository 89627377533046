import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/modal/modal.service';
import { MustMatch } from 'src/app/services/Validation/must-match.validation';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  password: boolean;
  confirmpassword: boolean;
  submitted = false;

  constructor(
    private commonService: CommonService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private storage:StorageService
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      newPassword: new UntypedFormControl('', Validators.compose([Validators.required])),
      confirmPassword: new UntypedFormControl('', Validators.compose([Validators.required])),
    },
      {
        validator: MustMatch('newPassword', 'confirmPassword')
      }
    );
  }

  showPassword() {
    this.password = !this.password;
  }
  showConfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }
  get a() {
    return this.resetPasswordForm.controls;
  }

  resetPassword() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }
    let savedData=this.storage.getSavedData();
    this.commonService.presentSpinner();

    let payload = {
      password : this.resetPasswordForm.value.newPassword,
      confirmPassword: this.resetPasswordForm.value.confirmPassword,
      adminId:'',
    };

    if(savedData && savedData.authData){
      payload.adminId=savedData.authData._id
    }
    this.authService
      .resetPassword(payload)
      .then((res: any) => {
        if (res.code == 200) {
          localStorage.clear();
          this.commonService.dismissSpinner();
          this.commonService.showSuccessToastMsg(res.message);
          this.router.navigate(['/login']);

        }
      })
      .catch((error) => {
        this.commonService.dismissSpinner();
      })
      .finally(() => {
        this.commonService.dismissSpinner();
      });
  }


}
