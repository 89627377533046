import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../../storage/storage.service';
import { Storage_Keys } from '../../storage/storage.keys';
import { tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CommonService } from '../../common/common.service';
import { EndpointTwo } from '../http-wrapper/endpoint';

@Injectable()
export class Interceptor implements HttpInterceptor {
  encountered401: boolean = false;
  constructor(
    private storage: StorageService,
    private router: Router,
    private commonService: CommonService
  ) { }

  // intercept(request: HttpRequest<any>, next: HttpHandler) {
  //   let headers = {};
  //   let savedData = this.storage.getSavedData();
  //   if (savedData && savedData[Storage_Keys.token]) {
  //     let token = savedData[Storage_Keys.token].token;
  //     headers['Authorization'] = token;
  //   }
  //   const interceptedRequest = request.clone({
  //     "headers": new HttpHeaders(headers)
  //   });
  //   return next.handle(interceptedRequest).pipe(
  //     tap(
  //       (event: HttpEvent<any>) => {

  //       },
  //       (error) => {
  //         if (error instanceof HttpErrorResponse) {
  //           if (error.status == 401) {
  //             this.storage.clearStorageForLogout();
  //             this.router.navigate(['/login']);
  //           }
  //         }
  //       }
  //     )
  //   )
  // }

  intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    let savedData = this.storage.getSavedData();
    let headers = {};
    if (savedData && savedData[Storage_Keys.token]) {
      headers['Authorization'] = savedData[Storage_Keys.token];

      // if (!request.url.includes(EndpointTwo.auth.resetPassword) && request.url.includes(environment.baseUrl) && !request.url.includes('/supplier/')) {

      // } 
      // else {

      //  if (request.method.toLowerCase() == "post") {
      //     request = request.clone({
      //       responseType: "blob",
      //       "reportProgress": true
      //     });
      //   }
      // }
    }
    let interceptedRequest = request.clone({
      "headers": new HttpHeaders(headers)
    });
    if (request.url.includes('login')) {
      this.encountered401 = false;
    }
    if (this.encountered401) {
      return EMPTY;
    }
    return handler.handle(interceptedRequest).pipe(
      tap(
        (event: HttpEvent<any>) => {

        },
        (error: HttpErrorResponse) => {
          if (error && error.status == 401) {
            this.encountered401 = true;
            this.storage.clearStorageForLogout().then(
              () => {
                localStorage.clear();
                this.router.navigate(['/login']).finally(() => {
                  this.commonService.showErrorToastMsg('Session expired. Please login!');
                });
              }
            )
          }
        }
      )
    );
  }

}
