import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage/storage.service';
import { CommonService } from '../services/common/common.service';
import { UserRole } from '../constants/user.roles';
import { Storage_Keys } from '../services/storage/storage.keys';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private storage: StorageService,
    private commonservice: CommonService,
    private router: Router
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let savedData = this.storage.getSavedData();
    let loggedIn = false;
    let role: UserRole | null = null;
    if (savedData && savedData[Storage_Keys.token]) {
      loggedIn = true;
      role = savedData[Storage_Keys.admin_data].role;
    }
    let type: "both" | "postLogin" | "preLogin" = "both";
    let roles: Array<UserRole> | null = null;
    if (route.data) {
      type = route.data['type'];
      roles = route.data['roles'] || [];
    }

    if (loggedIn) {
      if (type && type == 'preLogin') {
        this.router.navigate(['/']);
        return false;
      }

      if (type == 'postLogin' || type == 'both') {
        if (roles && role && roles.find(x => x.toLowerCase() == role && role.toLowerCase())) {
          return true;
        } else {
          this.router.navigate(['/']);
          this.commonservice.showErrorToastMsg('This page is not accessible.');
          return false;
        }
      }

      return true;
    } else {
      if (type == 'postLogin') {
        this.commonservice.showErrorToastMsg("You're not logged in!");
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
      } else {
        return true;
      }
    }
  }
}


