import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { regex } from 'src/app/services/http/http-wrapper/regex';
import { Storage_Keys } from 'src/app/services/storage/storage.keys';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-otp-request',
  templateUrl: './otp-request.component.html',
  styleUrls: ['./otp-request.component.scss']
})
export class OtpRequestComponent {
  sendOtpForm:FormGroup;
  submitted=false;

  constructor(
    private authService:AuthService,
    private commonService:CommonService,
    private router:Router,
    private storage:StorageService,

  ) { }

  ngOnInit(): void {
    this.sendOtpForm=new  FormGroup({
      email : new FormControl('',Validators.compose([Validators.required,Validators.pattern(regex.email)])),
    });
  }

  get a(){
    return this.sendOtpForm.controls;
  }

  sendOtp(){
    this.submitted=true;
    if(this.sendOtpForm.invalid){
      return;
    }
    let savedData=this.storage.getSavedData();
    if(savedData && savedData.authData){
      this.sendOtpForm.value.adminId==savedData.authData._id
    }
    this.commonService.presentSpinner();
    this.authService.sendOtp(this.commonService.convertEmailToLowerCase(this.sendOtpForm.value, 'forgot-password')).then((res:any)=>{
      if(res.code ==200){
        this.storage.set(Storage_Keys.authData, res);

        this.commonService.dismissSpinner();
        this.commonService.showSuccessToastMsg(res.message);
        this.router.navigate(['/send-verification']);
      }
    }).catch((error)=>{
      this.commonService.dismissSpinner();
    }).finally(()=>{
      this.commonService.dismissSpinner();

    })
  }
 
}