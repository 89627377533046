import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
// import { AdminModule } from './components/admin/admin.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ModalModule } from './modal/modal.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './services/http/interceptor/interceptor';
import { DatePipe } from '@angular/common';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';;
import { DecimalDirective } from './services/directive/decimal.directive';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OtpRequestComponent } from './components/otp-request/otp-request.component';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';
import { Angulartics2Module } from 'angulartics2';
const GOOGLE_MAPS_API_KEY = 'AIzaSyA7X9NleruEitSXMSJsgRadCuiMT8t8jiM';
// 

@NgModule({
  declarations: [AppComponent, ForgotPasswordComponent, LoginComponent, DecimalDirective, OtpRequestComponent, OtpVerificationComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // AdminModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgApexchartsModule,
    ModalModule,
    AngularEditorModule,
    NgSelectModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      timeOut: 5000,
      enableHtml: true
    }),
    Angulartics2Module.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: GOOGLE_MAPS_API_KEY,
      libraries: ['places'],
    }),
    NgMultiSelectDropDownModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    NgxImageCompressService,
    DatePipe, GoogleMapsAPIWrapper,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
